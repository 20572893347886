.fade-animate {
  transition: all 0.5s ease;
}

.fade-animate:hover {
  animation: subtle-fade 1.5s infinite alternate;
  animation-timing-function: ease;
}

@keyframes subtle-fade {
  0% {
    opacity: 1;
    filter: blur(0);
  }
  100% {
    opacity: 0.7;
    filter: blur(2px);
  }
}
